import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/navbar";

const NotFoundPage = () => (
  <Layout>
      <Navbar />
      <div className="dashInfo">
          <div className="titleGeneral h-1/4">
              <div className="font-bold text-white text-center pt-8 pb-12">
                  Opps!
              </div>
          </div>
          <div className="bg-white pt-6 px-6 overflow-x-auto h-3/4 rounded-t-3xl -mt-8">
              <h1>404: No encontrado</h1>
              <p>La página que buscas no se encuentra</p>
          </div>
      </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
